.not-found-container{
    height: 100vh;
    width: 100%;
    background-color: #ffadad;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.not-found-title{
    font-size: 3rem;
    font-weight: 600;
    color: #ff1744;
    text-align: center;
    z-index: 3;
}

.not-found-image{
    position: absolute;
    /* top: -2rem; */
    left: 1rem;
    bottom: 1rem;
    opacity: 0.5;

}

.not-found-countdown{
    font-size: 1rem;
    font-weight: 600;
    color: #ff1744;
    text-align: center;
    z-index: 3;
}