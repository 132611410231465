/* framer-motion */
.container {
    display: block;
}

.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #b3e5fc;
    overflow: hidden;
    position: relative;
}

.cloud-container {
    z-index: 2;
    position: absolute;
}

.cloud-container-left{
    top: 20rem;

}

.cloud-container-right{
top: -5rem;
}

.sun-container {
    z-index: 1;
    /* bottom of div */
    position: absolute;
    bottom: -20rem;
}

.title {
    position: absolute;
    font-size: 5rem;
    font-weight: 600;
    color: #2196f3;
    text-align: center;
    z-index: 3;
}

.second-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #b2dfdb;
    overflow: hidden;
    position: relative;
}

.second-title {
    font-size: 3rem;
    font-weight: 600;
    color: #009688;
    text-align: center;
    z-index: 3;
}

.arrow-up-down-container{
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2rem;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.arrow-button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    /* background-color: #2196f3; */
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #b39ddb;
    overflow: hidden;
}

.footer-title {
    font-size: 3rem;
    font-weight: 600;
    /* color: #673ab7; */
    text-align: center;
}

.footer-credit {
    font-size: 1.5rem;
    font-weight: 600;
    /* color: #673ab7; */
    text-align: center;
}

.footer-credit a {
    text-decoration: none;
}
.footer-created-by {
    font-size: 1.5rem;
    font-weight: 600;
    /* color: #673ab7; */
    text-align: center;
}

.tree-container {
    position: absolute;
    bottom: 5rem;
    left: -6rem;
    z-index: 1;
}